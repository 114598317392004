import ItemCardView from "../Models/ItemCardView";

export enum HistoryState {
    Default,
    LeftMenu,
    Paging,
    Search
}

export interface HistorySubject {
    attach(historyEventType: HistoryState, observer: HistoryObserver): void;
    detach(historyEventType: HistoryState, observer: HistoryObserver): void;
    notify(data: IHistoryModel): void;
}


export interface HistoryObserver {
    historyUpdate(data: IHistoryModel): void
}

export interface IHistoryModel {
    categoryCode: string;
    searchWord: string;
    searchCount: number;
    displayStart: number;
    currentPage: number;
    viewType: ItemCardView;
    historyState: HistoryState;
}
