import { HistoryState, IHistoryModel } from "../History/HistoryInterface";
import ItemCardView from "./ItemCardView";

export default class MylnanHistoryModel implements IHistoryModel {
    searchCount: number;
    categoryCode: string;
    searchWord: string;
    displayStart: number;
    currentPage: number;
    viewType: ItemCardView;
    historyState: HistoryState;
}