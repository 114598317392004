
export default class BaseNavbar {
    private greenlandicFlag = <HTMLCollectionOf<HTMLAnchorElement>>document.getElementsByClassName("changeLangToKl");
    private danishFlag = <HTMLCollectionOf<HTMLAnchorElement>>document.getElementsByClassName("changeLangToDa");

    constructor() {
        this.Initialize();
    }

    private Initialize(): void{
        for (let elm of this.greenlandicFlag) {
            elm.onclick = (event) => {
                event.preventDefault();

                let location = decodeURI(window.location.pathname).split('/');

                let currentLocation = location.join('/');
                if (umbracoPages.includes(currentLocation)) {
                    window.location.href = "/kl/";
                    return;
                }
                location[1] = "kl"

                window.location.href = location.join('/');
            }
        }
        for (let elm of this.danishFlag) {
            elm.onclick = (event) => {
                event.preventDefault();

                let location = decodeURI(window.location.pathname).split('/');

                let currentLocation = location.join('/');
                if (umbracoPages.includes(currentLocation)) {
                    window.location.href = "/da/";
                    return;
                }
                location[1] = "da"

                window.location.href = location.join('/');
            }
        }
    }
}