var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"AddToCartOuter\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "\" data-uom=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"uom") : stack1), depth0))
    + "\">\r\n    <div class=\"AddToCart Clickable rounded-1\">\r\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"texts") : depth0)) != null ? lookupProperty(stack1,"txtAddToCart") : stack1), depth0))
    + "\r\n    </div>\r\n    <div class=\"AddToCartQuantity\">\r\n        <div class=\"AddToCartQuantityBox\">\r\n            <div class=\"AddToCartQuantityMinus Clickable\" data-quantity=\"-1\">\r\n                <svg x=\"0px\" y=\"0px\" width=\"18px\" height=\"18px\" viewBox=\"0 0 24 24\"><path d=\"M 3 11 L 3 13 L 21 13 L 21 11 L 3 11 z\"></path></svg>\r\n            </div>\r\n            <div class=\"AddToCartQuantityValue Clickable w-100\" aria-describedby=\"tipsy\" tabindex=\"0\">0</div>\r\n            <div class=\"AddToCartQuantityPlus Clickable\" data-quantity=\"1\">\r\n                <svg x=\"0px\" y=\"0px\" width=\"18px\" height=\"18px\" viewBox=\"0 0 24 24\"><path d=\"M 11 3 L 11 11 L 3 11 L 3 13 L 11 13 L 11 21 L 13 21 L 13 13 L 21 13 L 21 11 L 13 11 L 13 3 L 11 3 z\"></path></svg>\r\n            </div>\r\n        </div>\r\n        <div class=\"AddToCartQuantityInput w-100\" onclick=\"$(this).hide();\">\r\n            <div style=\"display: flex; align-items: center; flex-flow: column;\">\r\n                <div class=\"Input\">\r\n                    <input type=\"number\" min=\"0\" max=\"999\" maxlength=\"3\" class=\"QuantityInputText\" />\r\n                    <input class=\"QuantityInputButton btn\" type=\"button\" value=\"OK\">\r\n                </div>\r\n                <div class=\"Arrow2\">\r\n                    <svg x=\"0px\" y=\"0px\" width=\"50\" height=\"50\" viewBox=\"0 0 30 30\"><path d=\"M15,23c-0.256,0-0.512-0.098-0.707-0.293l-10-10c-0.286-0.286-0.372-0.716-0.217-1.09C4.23,11.243,4.596,11,5,11h20 c0.404,0,0.77,0.243,0.924,0.617c0.155,0.374,0.069,0.804-0.217,1.09l-10,10C15.512,22.902,15.256,23,15,23z\"></path></svg>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});