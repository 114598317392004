//Add for IE11 support
//import "core-js/stable";
import * as error from '../Scripts/Standard/ErrorHandling.js';
import BaseSearchV2 from './Base/BaseSearchV2';
import AjaxCallHelper from './Base/AjaxCallHelper';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
import HistoryPublisher from './History/HistoryPublisher';
import { HistoryState } from './History/HistoryInterface';
import baseShop from './Base/BaseShop';
import BaseNavbar from './Base/BaseNavbar';

globalThis.ErrorHandling = error as IErrorHandling;

class Main implements IMain {
    private _historyPublisher: HistoryPublisher;

    constructor() {
        this._historyPublisher = new HistoryPublisher();
        //globalThis.BaseShop = new baseShop();
        //var tempBaseShop = new baseShop();
    }

    public LoadMacro(categoryCode: string, pageSize: string, ShopCategoryItemId: string) {
        import('./Base/BaseItemPictures').then(async module => {
            let items = await AjaxCallHelper.GetItems(categoryCode, pageSize, '0');

            let macro = new module.default(ShopCategoryItemId, categoryCode,
                Number.parseInt(pageSize), 0, items, false, false,
                "col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12", true);

            this._historyPublisher.eventManager.attach(HistoryState.Default, macro);
        })
    }

    public LoadRelatedItems(itemNos: string, shopCategoryItemId: string) {
        import('./Base/BaseItemPictures').then(async module => {
            let items = await AjaxCallHelper.GetSpecificItems(itemNos);

            new module.default(shopCategoryItemId, "", 10, 0, items, false, false, "col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12");
        });
    }

    public LoadItemPictureView(categoryCode: string, pageSize: string, displayStart: string, shopCategoryHolderId: string, viewType: number = 0) {
        import('./Shop/ItemPictures/ItemPicturesMain').then(async module => {
            let main = await module.ItemPictureMain.Initialize(categoryCode, pageSize, displayStart, shopCategoryHolderId, viewType);
            this._historyPublisher.eventManager.attach(HistoryState.LeftMenu, main);
        });
    }

    public LoadDataTableView(categoryCode: string) {
        import('./Base/BaseDataTable').then(module => {
            new module.default(categoryCode);
        })
    }

    public LoadCheckoutStep3() {
        import('./Checkout/CheckoutStep3').then( module => {
            new module.default();
        });
    }

    public LoadItem() {
        import('./Shop/Item/Item').then(module => {
            module.default();
        });
    }

    public LoadUploadFile() {
        import('./Shop/Item/FileUpload').then(module => {
            module.default();
        });
    }

    public LoadFrontPage() {
        import('./Base/Frontpage').then(module => {
            new module.default();
        });
    }

    public LoadRecurringOrder() {
        import('./Shop/RecurringOrders/RecurringOrder').then(module => {
            new module.default();
        });
    }

    public Initialize() {
        globalThis.cartTexts = {
            txtAddToCart: txtAddToCart,
            txtReadMore: txtReadMore,
            txtShowMore: txtShowMore,
            txtDeal: txtDeal,
            txtNoImage: txtNoImage,
            txtDiscount: txtDiscount,
            txtItemNo: txtItemNo,
            txtNormalPrice: txtNormalPrice,
            txtUOM: txtUOM,
            txtYourPrice: txtYourPrice
        };

        let search = new BaseSearchV2();
        new BaseNavbar();
        //new Navbar();
        this._historyPublisher.eventManager.attach(HistoryState.LeftMenu, search);
        this._historyPublisher.eventManager.attach(HistoryState.Search, search);
        this._historyPublisher.eventManager.attach(HistoryState.Default, search);
    }

}

var tempMain = new Main();
var tempBaseShop = new baseShop();

globalThis.BaseShop = tempBaseShop;
globalThis.Main = tempMain;

document.addEventListener('DOMContentLoaded', () => {
    tempMain.Initialize();
    tempBaseShop.Initialize();
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        //@ts-ignore
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

}, { once: true });


