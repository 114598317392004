var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"imageLink") || (depth0 != null ? lookupProperty(depth0,"imageLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imageLink","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":35}}}) : helper)))
    + "\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <img class=\"w-100 px-1 opacity-50\" src=\"/Images/Icons/card-image.svg\">\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n<div class=\"d-flex gap-1 border-bottom pb-2\" style=\"font-size: 14px\">\r\n    <div style=\"flex: 0 0 35px;\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageLink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"flex-fill\">\r\n        <div class=\"Description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":11,"column":33},"end":{"line":11,"column":48}}}) : helper)))
    + "</div>\r\n        <div class=\"Quantity\" style=\"font-size: 12px; color: #d8d8d8\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"quantity") || (depth0 != null ? lookupProperty(depth0,"quantity") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quantity","hash":{},"data":data,"loc":{"start":{"line":12,"column":70},"end":{"line":12,"column":82}}}) : helper)))
    + "</div>\r\n    </div>\r\n    <div class=\"Amount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":33}}}) : helper)))
    + "</div>\r\n    <div class=\"ms-2\" style=\"flex: 0 0 19px;\">\r\n        <img src=\"/Images/Icons/trash-fill.svg\" \r\n             data-lineno=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"lineNo") || (depth0 != null ? lookupProperty(depth0,"lineNo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lineNo","hash":{},"data":data,"loc":{"start":{"line":17,"column":26},"end":{"line":17,"column":36}}}) : helper)))
    + "\" data-no=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"itemNo") || (depth0 != null ? lookupProperty(depth0,"itemNo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemNo","hash":{},"data":data,"loc":{"start":{"line":17,"column":47},"end":{"line":17,"column":57}}}) : helper)))
    + "\" data-uom=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"itemUOM") || (depth0 != null ? lookupProperty(depth0,"itemUOM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemUOM","hash":{},"data":data,"loc":{"start":{"line":17,"column":69},"end":{"line":17,"column":80}}}) : helper)))
    + "\" class=\"sidebarCartRemoveLine\"\r\n             style=\"cursor: pointer;\" height=\"19\" width=\"19\">\r\n    </div>\r\n</div>";
},"useData":true});