var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                                <img class=\"normalImage\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemImageLink") : depth0), depth0))
    + "\">\r\n                            </a>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                                <img class=\"normalImage invisible\" style=\"padding: 40px;\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemImageLink") : depth0), depth0))
    + "\">\r\n                                <span class=\"d-flex justify-content-center align-items-center w-100 h-100 position-absolute\"\r\n                                      style=\"color: gray;\">\r\n                                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"texts") : depth0)) != null ? lookupProperty(stack1,"txtNoImage") : stack1), depth0))
    + "\r\n                                </span>\r\n                            </a>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <h4 class=\"m-0\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"description_2") : stack1), depth0))
    + "</h4>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isItemOnDiscount") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":63,"column":31}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"ItemDiscountPercentage position-absolute\">\r\n                                -"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"deLineDiscPct") : stack1), depth0))
    + "%\r\n                            </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isGreenlandic") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":44,"column":28},"end":{"line":52,"column":35}}})) != null ? stack1 : "")
    + "                            <div class=\"d-flex flex-wrap-reverse\">\r\n                                <div class=\"Price PriceAlternative PriceMain CustomerUnitPriceExclVat Price4Chars\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "\">Kr. "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceExclVat") : depth0), depth0))
    + "</div>\r\n                                <div class=\"Price CustomerUnitPriceInclVat Price4Chars PriceOffer\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "\">Kr. "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceInclVat") : depth0), depth0))
    + "</div>\r\n                                <div class=\"Price PriceMain PriceWithoutDiscount\">\r\n                                    Kr. "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceWithoutDiscountInclVat") : depth0), depth0))
    + "\r\n                                </div>\r\n                            </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"ItemDiscountTextKL position-absolute\">\r\n                                    <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"texts") : depth0)) != null ? lookupProperty(stack1,"txtDeal") : stack1), depth0))
    + "</span>\r\n                                </div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"ItemDiscountText position-absolute\">\r\n                                    <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"texts") : depth0)) != null ? lookupProperty(stack1,"txtDeal") : stack1), depth0))
    + "</span>\r\n                                </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"Price PriceAlternative PriceMain CustomerUnitPriceExclVat Price4Chars\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "\">Kr. "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceExclVat") : depth0), depth0))
    + "</div>\r\n                            <div class=\"Price CustomerUnitPriceInclVat Price4Chars\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "\">Kr. "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceInclVat") : depth0), depth0))
    + "</div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                        <div></div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./AddToCart.handlebars"),depth0,{"name":"AddToCart","fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ReadMoreOuter\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "\" data-uom=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"uom") : stack1), depth0))
    + "\">\r\n                            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                                <div class=\"ReadMore Clickable rounded-1\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"texts") : depth0)) != null ? lookupProperty(stack1,"txtShowMore") : stack1), depth0))
    + "</div>\r\n                            </a>\r\n                        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n<div class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"bootstrapClass") : depth0), depth0))
    + " element ItemPartial2 border border-1\" \r\n     data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"no") : stack1), depth0))
    + "\" data-uom=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"uom") : stack1), depth0))
    + "\" data-sort=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"webshop_Created") : stack1), depth0))
    + "\">\r\n    <div class=\"ItemCardSameHeightWrapper d-flex w-100 flex-column\" style=\"overflow: hidden;\">\r\n        <div class=\"ItemHolder\">\r\n            <div>\r\n                <div class=\"itemImage\">\r\n                    <div class=\"img-container w-100\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"iDefaultPictureNo") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":21,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n                <div class=\"ItemTextHolder\">\r\n                    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                        <div class=\"Description\">\r\n                            <h3>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemDescription") : depth0), depth0))
    + "</h3>\r\n                            <div class=\"description2\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"description_2") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":31,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\r\n                        </div>\r\n                    </a>\r\n                </div>\r\n            </div>\r\n            <div class=\"d-flex align-items-end mt-2\">\r\n                <div class=\"itemPriceHolder flex-fill\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isItemForSale") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":66,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n                <div class=\"addToCartHolder\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isItemComplex") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":69,"column":20},"end":{"line":78,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});