var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"shopItemDiscountHolder col-sm-12\">\r\n        <div>\r\n            <span class=\"itemDiscountPercentage\">-"
    + alias4(((helper = (helper = lookupProperty(helpers,"lineDiscPct") || (depth0 != null ? lookupProperty(depth0,"lineDiscPct") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lineDiscPct","hash":{},"data":data,"loc":{"start":{"line":4,"column":50},"end":{"line":4,"column":65}}}) : helper)))
    + "%</span>\r\n        </div>\r\n        <span class=\"itemDiscountPrice me-3\">Kr. "
    + alias4(((helper = (helper = lookupProperty(helpers,"customerUnitPriceInclVatIncDiscount") || (depth0 != null ? lookupProperty(depth0,"customerUnitPriceInclVatIncDiscount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"customerUnitPriceInclVatIncDiscount","hash":{},"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":88}}}) : helper)))
    + "</span>\r\n        <span class=\"itemOldPrice\">Kr. "
    + alias4(((helper = (helper = lookupProperty(helpers,"unitPriceInclVatExcelDiscount") || (depth0 != null ? lookupProperty(depth0,"unitPriceInclVatExcelDiscount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"unitPriceInclVatExcelDiscount","hash":{},"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":72}}}) : helper)))
    + "</span>\r\n    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"shopItemPrice col-sm-12\" data-no=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"itemCardNo") || (depth0 != null ? lookupProperty(depth0,"itemCardNo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemCardNo","hash":{},"data":data,"loc":{"start":{"line":10,"column":50},"end":{"line":10,"column":64}}}) : helper)))
    + "\">\r\n        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"customerUnitPriceInclVatInclDiscount") || (depth0 != null ? lookupProperty(depth0,"customerUnitPriceInclVatInclDiscount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"customerUnitPriceInclVatInclDiscount","hash":{},"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":54}}}) : helper)))
    + " DKK</span>\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasDiscount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});