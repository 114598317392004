import { forEach } from "underscore";
import { GetItemsRequest } from "../GeneratedModels/get-items-request";
import { ItemFilter } from "../GeneratedModels/item-filter";
import { ItemFilters } from "../GeneratedModels/item-filters";
import { ItemSort } from "../GeneratedModels/item-sort";
import { HistoryObserver, IHistoryModel } from "../History/HistoryInterface";
import HistorySingleton from "../History/HistorySingleton";
import ItemCardView from "../Models/ItemCardView";
import Item from "../Shop/Item/Item";
import LoadMoreItems from "../Shop/ItemPictures/ShopLoadMore";
import { Paging } from "../Shop/ItemPictures/ShopPaging";
import AjaxCallHelper from "./AjaxCallHelper";
import IsotopeHelper from "./IsotopeHelper";

//TODO remove Isotope!!!!!!!!!!!!!!!!!!!!!


export default class ItemPicturesV2 implements HistoryObserver {
    private _isotopeGrid: JQuery;
    private _isotopeGridJs: HTMLDivElement;
    private _normalItemImages: HTMLCollectionOf<Element>;
    private _bigItemImages: HTMLCollectionOf<Element>;
    private _itemHeight: number;
    private _root: HTMLElement;
    private _pageSize: number;
    private _displayStart: number;
    private _totalNumberOfItems: number;
    private _items: IItemPictureModel;
    private _oldItems: IItemPictureModel;
    private _bootstrapClass: string;
    private _loadMore: LoadMoreItems;
    private _searchSpinner: HTMLElement;
    private _categoryChangeSpinner = <HTMLDivElement>document.getElementById("ItemPicturesCategoryChangeSpinnerId");

    public _currentView: ItemCardView;

    //Filters
    private _Itemfilters: ItemFilters;
    private _sortSelect = <HTMLSelectElement>document.getElementById("orderBySelectId");
    private _sortSelectList = <HTMLCollectionOf<HTMLSelectElement>>document.getElementsByClassName("orderBySelectClass");
    private $rangeSlider = $<HTMLDivElement>(".js-slider-range");

    public _isotopeHolderId: string
    _paging: Paging;
    public _categoryCode: string;

    constructor(isotopeHolder: string, categoryCode: string, pageSize: number, displayStart: number,
        items: IItemPictureModel, enablePaging = false, enableLoadMore = false,
        argBootstrapClass?: string, macro: boolean = false, useFilters: boolean = false, itemViewType: ItemCardView = ItemCardView.ItemCardPicture) {

        if (!items) return;

        this._searchSpinner = document.getElementById("ItemPicturesSpinnerId");
        this._bootstrapClass = argBootstrapClass ?? "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12";
        this._isotopeGrid = $("#" + isotopeHolder);
        this._isotopeGridJs = <HTMLDivElement>document.getElementById(isotopeHolder);
        this._isotopeHolderId = isotopeHolder;
        this._totalNumberOfItems = items?.totalItems??0;
        this._categoryCode = categoryCode;
        this._pageSize = pageSize;
        this._displayStart = displayStart;
        this._currentView = itemViewType;
        //this._items = items;

        this._paging = enablePaging
            ? new Paging(this._pageSize, this._displayStart, this._totalNumberOfItems, this)
            : null;

        if(enableLoadMore) this._loadMore = new LoadMoreItems(this, this._totalNumberOfItems, this._pageSize);

        this._itemHeight = 0;
        this._root = document.documentElement;
        this._normalItemImages = document.getElementById(isotopeHolder).getElementsByClassName("normalImage");
        this._bigItemImages = document.getElementById(isotopeHolder).getElementsByClassName("biggerImage");

        this.loadItemPictures(items);

        if (useFilters) this.InitializeFilters();
    }

    historyUpdate(data: IHistoryModel): void {
        console.log("history");
        this.ManulUpdateIsotopeLayout();
    }

    public ChangeToImageListView(viewType: ItemCardView) {
        IsotopeHelper.ChangeToImageListView(this._isotopeGridJs, this._items?.webshopItemCards, this._currentView)
        this._currentView = viewType;
    }

    public ChangeToImageView(viewType: ItemCardView) {
        IsotopeHelper.ChangeToImageView(this._isotopeGridJs, this._items?.webshopItemCards, this._currentView, this._bootstrapClass)
        this._currentView = viewType;
    }

    public async UpdateItemsByCategory(categoryCode: string, displayStart: number = 0, currentPage: number = 1) {
        console.log("updateItemsByCategory");
        if (categoryCode) this._categoryCode = categoryCode;

        //this.ShowHideSearchSpinner(true);
        this.ShowHideCategorySpinner(true);

        this._Itemfilters = {
            activeFilters: this._Itemfilters.activeFilters.filter(item => item === ItemFilter.SortFilter),
            itemSort: this._Itemfilters.itemSort,
            priceFrom: 0,
            priceTo: 0
        };

        var items = await AjaxCallHelper.GetItems(categoryCode, this._pageSize.toString(), displayStart.toString(), this._Itemfilters);
        if (items === null) {
            //this.ShowHideSearchSpinner(false);
            this.ShowHideCategorySpinner(false);
            return;
        }

        if (this._paging) this._paging.ChangeCategory(items.totalItems, currentPage);

        if(this._loadMore) this._loadMore.UpdateLoadMore(items.totalItems);

        this.UpdateFilters(items);
        this.loadItemPictures(items, true);
        this.ShowHideCategorySpinner(false);
    }

    public SearchItems(items: IItemPictureModel): void {
        this.loadItemPictures(items, true);
    }

    public UpdateItems(items: IItemPictureModel) {
        this.loadItemPictures(items);
    }

    public async HandlePaging(displayStart: string) {
        this.ShowHideSearchSpinner(true);
        var items = await AjaxCallHelper.GetItems(this._categoryCode, this._pageSize.toString(), displayStart);
        if (items === null) {
            this.ShowHideSearchSpinner(false);
            return;
        }

        this.loadItemPictures(items);
    }

    public async LoadMoreItems(displayStart: string) {
        this.ShowHideSearchSpinner(true);
        var items = await AjaxCallHelper.GetItems(this._categoryCode, this._pageSize.toString(), displayStart, this._Itemfilters);

        if (items === null) {
            this.ShowHideSearchSpinner(false);
            return;
        };
        
        this.AddMoreItems(items);
    }

    private AddMoreItems(items: IItemPictureModel) {
        //let temp = items.webshopItemCards.concat(this._items.webshopItemCards);

        this._isotopeGrid.off('layoutComplete');
        IsotopeHelper.AddAndRemoveElements2(this._isotopeGridJs, this._items?.webshopItemCards ?? null, items.webshopItemCards, this._currentView, this._bootstrapClass);

        this._isotopeGrid.one('layoutComplete', () => this.UpdateIsotopeLayout("AddMoreItems"));

        this.ShowHideSearchSpinner(false);
        BaseShop.SetupCartButtons();
        BaseShop.ReloadShopCart(true);
        this._items.webshopItemCards = items.webshopItemCards.concat(this._items.webshopItemCards);
    }

    private loadItemPictures(items: IItemPictureModel, emptyIsotope: boolean = false) {
        this._totalNumberOfItems = items?.totalItems??0;

        this._isotopeGrid.off('layoutComplete');

        if (emptyIsotope) {
            //this._isotopeGrid.isotope().empty();
            this._isotopeGrid.empty();
            IsotopeHelper.AddAndRemoveElements2(this._isotopeGridJs, null, items.webshopItemCards, this._currentView, this._bootstrapClass);
        } else {
            IsotopeHelper.AddAndRemoveElements2(this._isotopeGridJs, this._items?.webshopItemCards ?? null, items.webshopItemCards, this._currentView, this._bootstrapClass);
        }

        BaseShop.SetupCartButtons();
        BaseShop.ReloadShopCart(true);

        this.ShowHideSearchSpinner(false);
        $(".SearchSpinner").hide();

        this._items = items;
    }

    private async UpdateIsotopeLayout(caller: string) {
        //console.log("UpdateIsotopeLayout called: caller: " + caller);
        //this.HandleLargerImage();
        await this.Sleep(450);
        const parent = document.getElementById(this._isotopeHolderId);
        let minHeight = 0;
        this.setSameHeightWrapper();
        for (let item of parent.children) {
            if (item.firstElementChild === null) continue;
            //console.log(item.firstElementChild.clientHeight);
            if (item.firstElementChild.clientHeight > minHeight && !item.firstElementChild.classList.contains("biggerPicture")) {
                minHeight = item.firstElementChild.clientHeight;
            }
        }

        this.setSameHeightWrapper("unset");
        minHeight = minHeight + 2;

        //console.log(`current Height: ${this._itemHeight}`)
        //console.log(`new Height: ${minHeight}`)
        //console.log(`properyHeight: ${this.getMinHeight()}`)

        if (this._itemHeight === minHeight && this._itemHeight <= this.getMinHeight()) return;
        //if (minHeight === this.getMinHeight()) return;

        this._itemHeight = minHeight;
        this.setMinHeigt(minHeight);

        setTimeout(() => {
            this._isotopeGrid.isotope('layout');
        }, 100);
    }

    public ManulUpdateIsotopeLayout() {
        //console.log("Manual UpdateIsotopeLayout called");
        //this._isotopeGrid.isotope('layout');
    }

    private insertGrid() {
        this._isotopeGrid.isotope({
            itemSelector: '.element',
            percentPosition: true,
            transitionDuration: 0,
            layoutMode: 'fitRows',
            //fitRows: {
            //    gutter: '.grid-sizer'
            //},
            //masonry: {
            //    columnWidth: '.grid-sizer'
            //},
            getSortData: {
                no: '[data-no]',
                sort: '[data-sort]',
                description: '[Description]'
            },
            //sortBy: 'no',
            //sortBy: 'number',
            //sortBy: 'description',
            //sortAscending: true,
        });
    }

    private Sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private setSameHeightWrapper(fitContent: string = "fit-content") {
        this._root.style.setProperty('--fitContentHeight', fitContent);
    }

    private setMinHeigt(minHeight) {
        //console.log(`first minHeight: ${minHeight}`);
        this._root.style.setProperty('--dynamicHeight', minHeight + "px");
    }

    private getMinHeight(): number {
        return parseInt(this._root.style.getPropertyValue('--dynamicHeight'));
    }

    private HandleLargerImage() {
        if (this._normalItemImages.length <= 0) return;
        let firstSmallImage = this._normalItemImages[0];

        for (let image of this._bigItemImages) {
            (image as HTMLElement).parentElement.parentElement.style.height = firstSmallImage.clientHeight.toString() + "px";
        }
    }

    private ShowHideCategorySpinner(show: boolean) {
        if (!this._categoryChangeSpinner) return;

        if (show) {
            this._isotopeGridJs.classList.add("opacity-50");
            this._categoryChangeSpinner.classList.remove("d-none");
        } else {
            this._isotopeGridJs.classList.remove("opacity-50");
            this._categoryChangeSpinner.classList.add("d-none");
        }
    }

    private ShowHideSearchSpinner(show: boolean) {
        if (!this._searchSpinner) return;
        if (show) {
            this._searchSpinner.style.display = "block";
        } else {
            this._searchSpinner.style.display = "none";
        }
    }

    private UpdateFilters(items: IItemPictureModel): void {

        let sliderMinPrice = <HTMLInputElement>document.getElementById("js-price-min");
        let sliderMaxPrice = <HTMLInputElement>document.getElementById("js-price-max");

        this.$rangeSlider.slider("option", "max", items.highestItemPrice);
        this.$rangeSlider.slider("option", "min", items.lowestItemPrice);
        this.$rangeSlider.slider("values", [items.lowestItemPrice, items.highestItemPrice]);

        sliderMinPrice.dataset.min = items.lowestItemPrice.toString();
        sliderMaxPrice.dataset.max = items.highestItemPrice.toString();
        console.log(items.lowestItemPrice);
        $(".js-slider-min").val(items.lowestItemPrice + ' DKK');
        $(".js-slider-max").val(items.highestItemPrice + ' DKK');

    }

    private InitializeFilters(): void {
        this._Itemfilters = {
            activeFilters: new Array(),
            itemSort: ItemSort.None,
            priceFrom: 0,
            priceTo: 0
        };

        this.AddSortbyFilter();
        this.AddPriceSliderFilter();
    }

    private AddPriceSliderFilter(): void {
        let sliderMinPrice = <HTMLInputElement>document.getElementById("js-price-min");
        let sliderMaxPrice = <HTMLInputElement>document.getElementById("js-price-max");

        let maxSliderPriceStr = this._items.highestItemPrice.toString();
        let minSliderPriceStr = this._items.lowestItemPrice.toString();

        //sliderMaxPrice.value = maxSliderPriceStr + " DKK";
        //sliderMinPrice.value = minSliderPriceStr + " DKK";

        $(".js-slider-min").val(minSliderPriceStr + ' DKK');
        $(".js-slider-max").val(maxSliderPriceStr + ' DKK');

        sliderMinPrice.dataset.min = minSliderPriceStr;
        sliderMaxPrice.dataset.max = maxSliderPriceStr;

        let min = $("#js-price-min").data("min") as number;
        let max = $("#js-price-max").data("max") as number;

        this.$rangeSlider.slider({
            range: true,
            min: min,
            max: max,
            step: 1,
            values: [min, max],
            slide: (a, ui) => {
                //$("#js-price-min").val(ui.values[0] + ' DKK');
                $(".js-slider-min").val(ui.values[0] + ' DKK');
                //$("#js-price-max").val(ui.values[1] + ' DKK');
                $(".js-slider-max").val(ui.values[1] + ' DKK');
            },
            stop: async (event, ui) => {
                let currentMinValue = Math.floor(ui.values[0]);
                let currentMaxValue = Math.ceil(ui.values[1]);

                this._Itemfilters.priceFrom = currentMinValue;
                this._Itemfilters.priceTo = currentMaxValue;

                if (currentMaxValue === max && currentMinValue === min) {
                    this._Itemfilters.activeFilters = this._Itemfilters.activeFilters.filter(item => item !== ItemFilter.PriceFilter);

                } else {
                    if (!this._Itemfilters.activeFilters.includes(ItemFilter.PriceFilter))
                        this._Itemfilters.activeFilters.push(ItemFilter.PriceFilter);
                }

                let items = await AjaxCallHelper.GetItems(this._categoryCode, this._pageSize.toString(), "0", this._Itemfilters);

                if(this._loadMore) this._loadMore.UpdateLoadMore(items.totalItems);

                this.loadItemPictures(items, true);
            }
        });

        //this.$rangeSlider.find(".ui-slider-handle").draggable();
    }

    private AddSortbyFilter(): void {
        for (let select of this._sortSelectList) {
            select.addEventListener("change", async () => {
                let current = event.currentTarget as HTMLSelectElement;

                switch (parseInt(current.value)) {
                    case ItemSort.PriceAsc:
                        if (!this._Itemfilters.activeFilters.includes(ItemFilter.SortFilter))
                            this._Itemfilters.activeFilters.push(ItemFilter.SortFilter);

                        this._Itemfilters.itemSort = ItemSort.PriceAsc;
                        break;
                    case ItemSort.PriceDesc:
                        if (!this._Itemfilters.activeFilters.includes(ItemFilter.SortFilter))
                            this._Itemfilters.activeFilters.push(ItemFilter.SortFilter);

                        this._Itemfilters.itemSort = ItemSort.PriceDesc;
                        break;
                    default:
                        this._Itemfilters.activeFilters = this._Itemfilters.activeFilters.filter(item => item !== ItemFilter.SortFilter);
                        this._Itemfilters.itemSort = ItemSort.None;
                        break;
                }

                let items = await AjaxCallHelper.GetItems(this._categoryCode, this._pageSize.toString(), "0", this._Itemfilters);

                if(this._loadMore) this._loadMore.UpdateLoadMore(items.totalItems);

                this.loadItemPictures(items, true);
            });
        }
    }
}

