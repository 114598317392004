import { WebshopItemCard } from "../GeneratedModels/webshop-item-card";
import tempItem from '../Templates/ItemCard.handlebars';
import itemCardPictureListTemp from '../Templates/ItemCardPictureList.handlebars';
import _ from 'lodash';
import ItemCardView from "../Models/ItemCardView";

declare var bootstrapClass;

export default class IsotopeHelper {

    public static AddAndRemoveElements2(argContainer: HTMLDivElement, oldResult: WebshopItemCard[], newResult: WebshopItemCard[],
        viewType: ItemCardView = ItemCardView.ItemCardPicture,
        argBootstrapClass: string = "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"): void
    {
        let noResult = argContainer.querySelector(".NoResult");
        if (noResult) argContainer.removeChild(noResult);

        //Only used to load more
        if (oldResult !== null) {
            let differentItems = _.differenceBy(newResult, oldResult, (x) => x)

            for (let item of differentItems) {
                let tempDiv = document.createElement("div");
                tempDiv.innerHTML = this.CreateElementV2(item, argBootstrapClass, viewType);;
                argContainer.appendChild(tempDiv.firstElementChild);
            }

        } else {
            let elmStr = "";
            for (let item of newResult)
                elmStr += this.CreateElementV2(item, argBootstrapClass, viewType);

            argContainer.innerHTML = elmStr;
        }

        if (newResult.length === 0) {
            let $nothingFoundElement = '<div style="padding: 0 15px;" class="element col-12 ItemPartial NoResult Inventory"><p>'
                + txtNothingFoundTryAdjustingYourSearchTermsToFindResults + '</p></div>';
            //argContainer.isotope('insert', $nothingFoundElement);
            //argContainer.append($nothingFoundElement);
            argContainer.innerHTML = $nothingFoundElement;
        }

        BaseShop.LoadInventory();
    }

    //public static AddAndRemoveElements(argContainer: JQuery<HTMLElement>, oldResult: WebshopItemCard[], newResult: WebshopItemCard[],
    //    argBootstrapClass: string = "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"): void {

    //    //argContainer.isotope('remove', argContainer.find(".NoResult"));
    //    argContainer.remove(".NoResult");
    //    let localThis = this;

    //    if (oldResult !== null) {
    //        // Loop old results and find elements to remove
    //        for (let oldItem of oldResult) {
    //            var found = false;
    //            for (let newItem of newResult) {
    //                if (oldItem.no === newItem.no && oldItem.uom === newItem.uom) {
    //                    found = true;
    //                    break;
    //                }
    //            }

    //            if (!found) {
    //                argContainer.isotope('remove', argContainer.find("[data-no='" + oldItem.no + "'][data-uom='" + oldItem.uom + "']"));
    //            }
    //        }

    //        // Loop new results and find elements to add
    //        for (let item of newResult) {
    //            var found = false;
    //            for (let oldItem of oldResult) {
    //                if (oldItem.no === item.no && oldItem.uom === item.uom) {
    //                    found = true;
    //                    break;
    //                }
    //            }
    //            if (!found) {
    //                //argContainer.isotope('insert', $(localThis.CreateElement(item, argBootstrapClass)))
    //                let elm = $(localThis.CreateElementV2(item, argBootstrapClass));
    //                //argContainer.isotope().append( elm ).isotope('appended', elm).isotope('layout');
    //                argContainer.append(elm).isotope("appended", elm);
    //            }
    //        }

    //        argContainer.isotope();
    //    } else {
    //        var newEls = '';
    //        for (let item of newResult) {
    //            newEls += this.CreateElementV2(item, argBootstrapClass);
    //        }
    //        var $newEls = $(newEls);

    //        //document.getElementById(argContainer[0].id).innerHTML += this.CreateGridSizer(argBootstrapClass);
    //        //argContainer.isotope('insert', $newEls);
    //        argContainer.append($newEls);
    //    }

    //    if (newResult.length === 0) {
    //        let $nothingFoundElement = $('<div style="padding: 0 15px;" class="element col-12 ItemPartial NoResult Inventory"><p>'
    //            + txtNothingFoundTryAdjustingYourSearchTermsToFindResults + '</p></div>');
    //        //argContainer.isotope('insert', $nothingFoundElement);
    //        argContainer.append($nothingFoundElement);
    //    }

    //    BaseShop.LoadInventory();
    //}

    public static ChangeToImageListView(argContainer: HTMLDivElement, currentItemCards: WebshopItemCard[], currentViewType: ItemCardView): void {
        if (!currentItemCards || currentViewType === ItemCardView.ItemCardPictureList) return;

        argContainer.innerHTML = "";
        let tempstr = "";
        
        for (let item of currentItemCards) {
            tempstr += this.CreateElementV2(item, "", ItemCardView.ItemCardPictureList);
        }

        argContainer.innerHTML = tempstr;
        BaseShop.SetupCartButtons();
        BaseShop.ReloadShopCart(true);
    }

    public static ChangeToImageView(argContainer: HTMLDivElement, currentItemCards: WebshopItemCard[],  currentViewType: ItemCardView, argBootstrapClass: string): void {
        if (!currentItemCards || currentViewType === ItemCardView.ItemCardPicture) return;

        argContainer.innerHTML = "";
        let tempstr = "";
        
        for (let item of currentItemCards) {
            tempstr += this.CreateElementV2(item, argBootstrapClass, ItemCardView.ItemCardPicture);
        }

        argContainer.innerHTML = tempstr;
        BaseShop.SetupCartButtons();
        BaseShop.ReloadShopCart(true);
    }

    private static CreateElementV2(argItem: WebshopItemCard, argBootstrapClass: string, viewType: ItemCardView): string {
        let itemLink = "";
        let itemDescription = argItem.uomDescription
                ? `${argItem.description} (${argItem.uomDescription})`
                : argItem.description;

        if (argItem.unitOfMeasures.length > 1) {
            itemLink = `/${culture}/nethandil/Item/${argItem.no}`
        }
        else {
            itemLink = `/${culture}/nethandil/Item/${argItem.no}/${argItem.uom}`;
        }

        let itemImageLink = "";

        if (argItem.iDefaultPictureNo > 0) {
            itemImageLink = `/Images/Items/${companyName}/W450/${argItem.iDefaultPictureNo}${argItem.coDefaultPictureExtension}`;
        } else {
            itemImageLink = `/Images/Icons/card-image.svg`;
        }

        let customerUnitPriceExclVat = argItem.deCustomerUnitPriceSpecified ? argItem.deCustomerUnitPrice : 0;
        let customerUnitPriceInclVat = argItem.deCustomerUnitPriceInclVATSpecified ? argItem.deCustomerUnitPriceInclVAT : 0;

        if (argItem.unitOfMeasures !== null && argItem.unitOfMeasures.length > 1) {
            for (var i = 0; i < argItem.unitOfMeasures.length; i++) {
                if (argItem.unitOfMeasures[i].code === argItem.sales_Unit_of_Measure) {
                    customerUnitPriceExclVat = argItem.deCustomerUnitPriceSpecified ? argItem.unitOfMeasures[i].deCustomerUnitPrice : 0;
                    customerUnitPriceInclVat = argItem.deCustomerUnitPriceInclVATSpecified ? argItem.unitOfMeasures[i].deCustomerUnitPriceInclVAT : 0;
                }
            }
        }

        if (argItem.deLineDiscPctSpecified && argItem.deLineDiscPct !== 0) {
            customerUnitPriceExclVat = customerUnitPriceExclVat / 100 * (100 - argItem.deLineDiscPct);
            customerUnitPriceInclVat = customerUnitPriceInclVat / 100 * (100 - argItem.deLineDiscPct);
        }

        let priceInclVatString = customerUnitPriceInclVat.toLocaleString("da-DK", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(",00", ",-");
        let priceExclVatString = customerUnitPriceExclVat.toLocaleString("da-DK", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(",00", ",-");
        let priceWithoutDiscountString = argItem.deUnitPriceInclVAT.toLocaleString("da-DK", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(",00", ",-");

        let viewObj = {
            itemCard: argItem,
            itemDescription: itemDescription,
            texts: cartTexts,
            bootstrapClass: argBootstrapClass,
            itemLink: itemLink,
            itemImageLink: itemImageLink,
            isItemComplex: !this.isItemComplex(argItem),
            isItemForSale: !argItem.webshop_No_Sale && customerUnitPriceInclVat !== -1,
            itemPriceInclVat: priceInclVatString,
            itemPriceExclVat: priceExclVatString,
            itemPriceWithoutDiscountInclVat: priceWithoutDiscountString,
            isItemOnDiscount: Math.round(customerUnitPriceInclVat) != Math.round(argItem.deUnitPriceInclVAT),
            isGreenlandic: culture === "kl"
        }

        return viewType === ItemCardView.ItemCardPicture
            ? tempItem(viewObj)
            : itemCardPictureListTemp(viewObj);
    }



    private static CreateGridSizer(argBootstrapClass: string) {
        var html = '<div class="grid-sizer ' + argBootstrapClass + '"></div>'
        return html;
    }

    private static isItemComplex(argItem: WebshopItemCard): boolean {
        let isItemComplex = argItem.item_Variants?.length !== 0 || argItem.unitOfMeasures?.length > 1 || argItem.wS_Item_Collection?.length != 0;
        if (isItemComplex)
            return true;

        return false;
    }

    public static GetSpinner(): HTMLImageElement {
        let searchResultSpinnerImg = document.createElement("img");
        searchResultSpinnerImg.classList.add("SearchSpinner");
        searchResultSpinnerImg.src = "/Images/Spinner_16.gif";

        return searchResultSpinnerImg;
    }
}

