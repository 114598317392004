var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"imageLink") || (depth0 != null ? lookupProperty(depth0,"imageLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imageLink","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":35}}}) : helper)))
    + "\" style=\"object-fit: contain;\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"imageLink") || (depth0 != null ? lookupProperty(depth0,"imageLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imageLink","hash":{},"data":data,"loc":{"start":{"line":6,"column":22},"end":{"line":6,"column":35}}}) : helper)))
    + "\" class=\"opacity-50\" style=\"object-fit: contain; width: 80px;\">\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\r\n    <div class=\"d-flex p-4 notyImageCell\" style=\"max-height: 130px; justify-content: center;\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useDefaultImage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n    <div>\r\n        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":10,"column":13},"end":{"line":10,"column":28}}}) : helper)))
    + "</div>\r\n        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":11,"column":13},"end":{"line":11,"column":22}}}) : helper)))
    + "</div>\r\n        <div class=\"d-flex justify-content-end\">\r\n            <input type=\"button\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"txtToCheckout") || (depth0 != null ? lookupProperty(depth0,"txtToCheckout") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"txtToCheckout","hash":{},"data":data,"loc":{"start":{"line":13,"column":40},"end":{"line":13,"column":57}}}) : helper)))
    + "\" class=\"btn btn-primary\" onclick=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"onclickLink") || (depth0 != null ? lookupProperty(depth0,"onclickLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"onclickLink","hash":{},"data":data,"loc":{"start":{"line":13,"column":92},"end":{"line":13,"column":107}}}) : helper)))
    + "\">\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});