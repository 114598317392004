window.onerror = function (errorMessage, errorUrl, errorLine) {
    var message = "<p>navigator.userAgent: " + navigator.userAgent.toString() + "</p>" +
        "<p>errorMessage: " + errorMessage.toString() + "</p>" +
        "<p>errorUrl: " + errorUrl.toString() + "</p>" +
        "<p>errorLine: " + errorLine.toString() + "</p>" +
        "<p>window.location.href: " + window.location.href + "</p>";

    SendJavascriptErrorToServer(message);

    return false;
};

export function HandleAjaxError(source, xhr, status, error) {
    var message = "<p>source: " + source + "</p>" +
        "<p>navigator.userAgent: " + navigator.userAgent.toString() + "</p>" +
        "<p>error: " + error.toString() + "</p>";

    if (xhr !== null && xhr.responseJSON !== null && xhr.responseJSON !== undefined && xhr.responseJSON.ExceptionMessage !== null)
        message += "<p>xhr.responseJSON.ExceptionMessage: " + xhr.responseJSON.ExceptionMessage + "</p>";
    else if (xhr !== null && xhr.responseJSON !== null && xhr.responseJSON !== undefined && xhr.responseJSON.Message !== null)
        message += "<p>xhr.responseJSON.Message: " + xhr.responseJSON.Message + "</p>";

    message += "<p>JSON.stringify(xhr): " + JSON.stringify(xhr) + "</p>" +
        "<p>status: " + status.toString() + "</p>";

    if (xhr !== null && xhr.readyState === "0" && xhr.status === "0")
        return;

    SendJavascriptErrorToServer(message);

    if (xhr.responseJSON !== null && xhr.responseJSON !== undefined && xhr.responseJSON.ExceptionMessage !== null)
        Shop.NotyError(xhr.responseJSON.ExceptionMessage);
        
    else
        BaseShop.NotyError(message);
}

export function SendJavascriptErrorToServer(message) {
    jQuery.ajax({
        url: "/Umbraco/Api/JsonApi/JavascriptExeption",
        data: { "": message },
        type: 'POST',
        async: true,
        dataType: "json",
        success: function () {
            if (typeof window.console !== 'undefined' && console && console.log) {
                console.log('JS error report successful.');
            }
        },
        error: function () {
            if (typeof window.console !== 'undefined' && console && console.error) {
                console.error('JS error report submission failed!');
            }
        }
    });
}
